import React from "react"
import { BrowserRouter, Route } from "react-router-dom"
import appConfig from "src/appConfig"
import { paths } from "./paths"
import { Routes } from "./Routes"
import { SuspenseRoute } from "./SuspenseRoute"

const ConfirmEmailChange = React.lazy(
  () => import("src/views/ConfirmEmailChange")
)
const Forgot = React.lazy(() => import("src/views/Forgot"))
const Reset = React.lazy(() => import("src/views/Reset"))
const Login = React.lazy(() => import("src/views/Login"))
const Home = React.lazy(() => import("src/views/Home"))

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<SuspenseRoute />}>
        <Route
          path={paths.CONFIRM_EMAIL_CHANGE}
          element={<ConfirmEmailChange />}
        />
        <Route path={paths.RESET} element={<Reset />} />
        {appConfig.env === "development" ? (
          <>
            <Route path={paths.HOME} element={<Home />} />
            <Route path={paths.FORGOT} element={<Forgot />} />
            <Route path={paths.LOGIN} element={<Login />} />
          </>
        ) : null}
        <Route path="*" element={<div>404</div>} />
      </Route>
    </Routes>
  </BrowserRouter>
)
