import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import appConfig from "./appConfig"
import applyPolyfills from "./polyfills"

applyPolyfills()

appConfig.init()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
