import { colors } from "epsy-ui-react"
import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    overflow-y: scroll;
    height: 100%;
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 400;
    color: ${colors.shade[1]};
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  a {
    color: currentColor;
  }

  img, svg {
    vertical-align: bottom;
  }
`

export default GlobalStyle
