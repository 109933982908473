import { ErrorBoundary } from "@sentry/react"
import { AnalyticsContext } from "epsy-ui-react"
import React from "react"
import { Router } from "src/routing/Router"
import sentry from "src/services/sentry"
import Version from "./components/Version"
import { analytics } from "./services/analytics"
import GlobalStyle from "./style/global"

sentry.init()

const App: React.FC<{}> = () => {
  return (
    <ErrorBoundary showDialog>
      <AnalyticsContext.Provider value={analytics}>
        <GlobalStyle />
        <Router />
        <Version />
      </AnalyticsContext.Provider>
    </ErrorBoundary>
  )
}

export default App
