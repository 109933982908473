import {
  BrowserTracing,
  captureException,
  init,
  reactRouterV6Instrumentation,
  Replay,
  setUser,
  withProfiler,
  withSentryRouting
} from "@sentry/react"
import { Integration } from "@sentry/types"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom"
import appConfig from "src/appConfig"

const sentry = {
  init: () => {
    const tracingOrigins: (string | RegExp)[] = ["localhost", /^\//]
    init({
      dsn: appConfig.sentry.dsn,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      release: appConfig.version,
      environment: appConfig.env,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
          tracingOrigins
        }) as Integration,
        new Replay()
      ],
      tracesSampleRate: appConfig.sentry.tracesSampleRate
    })
  },
  captureException,
  setUser,
  withProfiler,
  withSentryRouting
}

export default sentry
