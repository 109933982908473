import { Amplify } from "aws-amplify"

// get create-react-app env var (REACT_APP_*)
function craEnv(suffix: string) {
  return process.env[`REACT_APP_${suffix}`] || ""
}

const env = craEnv("ENV") || "development"
const nodeEnv = process.env.NODE_ENV
const version = craEnv("VERSION") // is set in .env
const apiUrl = craEnv("API_URL")
const epsyAppLink = craEnv("EPSY_APP_LINK")

const sentry = {
  dsn: craEnv("SENTRY_DSN"),
  tracesSampleRate:
    env === "development"
      ? 1
      : craEnv("SENTRY_TRACES_SAMPLE_RATE")
      ? Number(craEnv("SENTRY_TRACES_SAMPLE_RATE"))
      : 0.1
}

const amplifyConfig = {
  Auth: {
    identityPoolId: craEnv("AMPLIFY_AUTH_IDENTITY_POOL_ID"),
    region: craEnv("AMPLIFY_AUTH_REGION"),
    userPoolId: craEnv("AMPLIFY_AUTH_USER_POOL_ID"),
    userPoolWebClientId: craEnv("AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID"),
    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
}
Amplify.configure(amplifyConfig)

const appConfig = {
  init: () => {},
  env,
  nodeEnv,
  version,
  sentry,
  apiUrl,
  epsyAppLink
}

export default appConfig
