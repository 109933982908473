function isEnabled(): boolean {
  const win = window as any
  return win.analytics && !win.Cypress
}

export const analytics = {
  page: (event: string) => {
    if (isEnabled()) {
      // @ts-ignore
      window.analytics.page(event)
    }
  },
  track: (event: string, data?: { [key: string]: any }) => {
    if (isEnabled()) {
      // @ts-ignore
      window.analytics.track(event, data)
    }
  },
  identify: (userId: string, traits?: { [key: string]: any }) => {
    if (isEnabled()) {
      // @ts-ignore
      window.analytics.identify(userId, traits)
    }
  }
}
