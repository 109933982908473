import { colors, Spinner } from "epsy-ui-react"
import React from "react"
import styled from "styled-components"

const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Loading: React.FC<{}> = (props) => {
  return (
    <SpinnerContainer>
      <Spinner size={32} color={colors.primary.purple} />
    </SpinnerContainer>
  )
}

export default Loading
